import React from 'react'
import { Link } from 'gatsby'
import CustomImage from 'components/foundations/Image/CustomImage'

const FeaturesBox = () => (
  <div className="closer">
    <div className="featureTitle-box">
      <h3>Consulenza ingegneristica avanzata per cogliere la sfida dell’innovazione</h3>
    </div>
    <div className="features-grid">
      <div className="feature-box">
        <CustomImage className="features-img" filename="diagram.png" />
        <div className="feature-content">
          <Link to="/consulenza">Consulenza</Link>
          <h6 className="feature-subtitle">Vi coccoliamo con i nostri consigli</h6>
        </div>
      </div>

      <div className="feature-box">
        <CustomImage className="features-img" filename="notebook.png" />
        <div className="feature-content">
          <Link to="/formazione">Formazione</Link>
          <h6 className="feature-subtitle">La formazione è la chiave del successo</h6>
        </div>
      </div>

      <div className="feature-box">
        <CustomImage className="features-img" filename="factory.png" />
        <div className="feature-content">
          <Link to="/ingegneria">Ingegneria</Link>
          <h6 className="feature-subtitle">Vi stupiremo con il rigore della follia</h6>
        </div>
      </div>

      <div className="feature-box">
        <CustomImage className="features-img" filename="medicsuitcase.png" />
        <div className="feature-content">
          <Link to="/medicina">Medicina</Link>
          <h6 className="feature-subtitle">Tutto è veleno, nulla è veleno: dipende dalla dose</h6>
        </div>
      </div>
    </div>
  </div>
)

export default FeaturesBox

/* eslint-disable no-undef */
import React from 'react'
import Button from 'components/foundations/Button/Button'
import { IoMdSettings } from 'react-icons/io'
import { CookieBanner } from '../../../services/react-cookie-law/src'

const removeCookies = () => {
  document.cookie = 'rcl_consent_given= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_preferences_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_statistics_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'rcl_marketing_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  window.location.reload()
}
const GDPRPopup = () => (
  <>
    <CookieBanner
      policyLink="/privacy-cookie-policy/"
      privacyPolicyLinkText="Privacy &amp; Cookie Policy"
      message="Questo sito utilizza cookie tecnici e di profilazione propri e di terze parti per le sue funzionalità e per inviarti pubblicità e servizi in linea con le tue preferenze. Clicca su uno dei pulsanti per accettare o rifiutare i cookie. Per accettare solamente alcuni cookie, clicca personalizza."
      necessaryOptionText="Essenziali"
      preferencesOptionText="Preferenze"
      statisticsOptionText="Statistiche"
      marketingOptionText="Marketing"
      acceptButtonText="Accetta"
      acceptAllButtonText="Accetta tutti"
      declineButtonText="Rifiuta tutti"
      optionsButtonText="Personalizza"
      showDeclineButton
      dismissOnScroll={false}
      showPreferencesOption={false}
      showStatisticsOption
      showMarketingOption
    />
    <Button onClick={removeCookies} className="cookies-settings">
      <IoMdSettings className="setting-icon" />
    </Button>
  </>
)
export default GDPRPopup

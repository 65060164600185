import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'components/foundations/Link/Link'

const HeroBox = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-image-ing-group.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const background = data.background.childImageSharp.fluid

  return (
    <div className="hero-homepage">
      <Img fluid={background} className="hero-image" />
      <div className="hero-text">
        <h1>Soluzioni avanzate e studiate su misura per te !</h1>
        <h1>Scopri i nostri numerosi corsi ...</h1>
        <Link to="/corsi">
          <span className="btn hero-btn">Info corsi</span>
        </Link>
      </div>
    </div>
  )
}

export default HeroBox
